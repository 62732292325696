<template>
    <div class="pt-3 pb-2 px-5 fl-x fl-te-c fl-a-c border-r-2 bg-4" :class="{'bg-primary':color === 'primary','text-primary':color===''}">
        <div>
            <h5 v-if="heading !==''" class="">{{ heading }}</h5>
            <div class="fl-x" v-else>
                <slot name="header"></slot>
            </div>
        </div>
        <div class="btn-group btn-block">
            <slot name="buttons"></slot>
        </div>

    </div>
</template>

<script>
export default {
    name: 'HeaderTag',
    props: {
        heading: {
            type: String,
            default: ''
        },
        headingBold: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        },
        accordionHeader: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
h5 {
    margin: 0;
}

.fa {
    color: black;
}

.btn-block {
    .lego-btn {
        color: black !important;

        span {
            color: black !important;
        }
    }
}
.border-r-1 {
  border-radius: 6px 6px 0 0;
}
.border-r-2 {
  border-radius: 9px 9px 0 0;
}
</style>
