<template>
    <div class="mb-3 mr-lg-6">
        <header-tag :color="color" class="mb-0 pt-0">
            <template #header>
                <h4 class="font-inter-medium">{{heading}}</h4>
            </template>
        </header-tag>
        <section class="px-lg-5 px-4 b-1 border-r-2-bt py-4 font-inter-regular">
            <slot></slot>
        </section>
    </div>
</template>
<script> import HeaderTag from '@components/HeaderTag';

export default {
    name: 'ListSectionCard',
    components: { HeaderTag },
    props: {
        heading: {
            type: String
        },
        color: {
            type: String,
            default: ''
        }
    }
};
</script>
<style scoped lang="scss">

</style>
