<template>
    <section v-if="dataLoading" class="about py-lg-9">
        <div class="text-center pt-7 pb-7">
            <loading-animation class="text-primary text-5x"/>
        </div>
    </section>

    <div v-else>
        <div v-if="details.length > 0" class="py-6">
            <div v-for="(item, index) in details" :key="index" class="container">
<!--                <div class="col-12" v-if="item.is_main">-->
<!--                    <list-section-card :heading="item.title" color="primary">-->
<!--                        <template #default>-->
<!--                            <p class="mb-4 text-justify">-->
<!--                                {{ item.description }}-->
<!--                            </p>-->
<!--                        </template>-->
<!--                    </list-section-card>-->
<!--                </div>-->
                <div class="col-12">
                    <list-section-card :heading="item.title">
                        <template #default>
                            <p v-html="item.description" class="mb-4 text-justify"/>
                        </template>
                    </list-section-card>
                </div>
            </div>
        </div>

        <div v-else class="py-6 container">
            <div class="fl-eqh-c">
                No data
            </div>
        </div>
    </div>

</template>

<script>
import ListSectionCard from '@components/faq/ListSectionCard';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'PrivacyAndPolicy',

    components: { ListSectionCard },

    data () {
        return {
            details: null,
            dataLoading: false
        };
    },

    mounted () {
        this.loadDetails();
    },

    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.privacyAndPolicy);
            const json = response.data;
            if (json.error === false) {
                this.details = [...json.data];
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }

};
</script>
